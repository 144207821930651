// Fixed tooltip_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tooltip"]
  static values = {
    position: { type: String, default: "top" },
    offset: { type: Number, default: 8 },
    delay: { type: Number, default: 0 }
  }
  
  connect() {
    // Make sure tooltip is hidden in CSS initially to prevent flashing
    if (!this.hasTooltipTarget) {
      console.warn("Tooltip controller requires a tooltip target");
      return;
    }
    
    // Store the tooltip content but keep the element hidden with CSS
    this.tooltipContent = this.tooltipTarget.innerHTML;
    
    // Hide with CSS - this prevents the flash before JS loads
    this.tooltipTarget.classList.add("hidden");
    
    // Bind event handlers
    this.element.addEventListener("mouseenter", this.handleMouseEnter.bind(this));
    this.element.addEventListener("mouseleave", this.hideTooltip.bind(this));
    this.element.addEventListener("click", this.hideTooltip.bind(this));
  }
  
  handleMouseEnter() {
    // Set up tooltip on first hover if not already done
    if (!this.setupComplete) {
      this.setupTooltip();
    }
    this.showTooltip();
  }
  
  setupTooltip() {
    // Create a new tooltip element
    this.tooltipElement = document.createElement("div");
    this.tooltipElement.innerHTML = this.tooltipContent;
    this.tooltipElement.classList.add(
      "fixed",
      "z-50",
      "bg-gray-800",
      "text-white",
      "px-2",
      "py-1",
      "rounded",
      "text-sm",
      "shadow-lg",
      "pointer-events-none",
      "opacity-0",
      "hidden",
      "transition-opacity",
      "duration-200"
    );
    
    // Add tooltip to the document body
    document.body.appendChild(this.tooltipElement);
    
    this.setupComplete = true;
  }
  
  disconnect() {
    // Clean up the tooltip element when disconnecting
    if (this.tooltipElement && document.body.contains(this.tooltipElement)) {
      document.body.removeChild(this.tooltipElement);
    }
  }
  
  showTooltip() {
    if (!this.tooltipElement && this.setupComplete === false) {
      this.setupTooltip();
    }
    
    if (!this.tooltipElement) return;
    
    clearTimeout(this.hideTimeout);
    
    this.showTimeout = setTimeout(() => {
      // Position the tooltip
      this.positionTooltip();
      
      // Show the tooltip
      this.tooltipElement.classList.remove("hidden");
      
      // Use requestAnimationFrame for smooth transition
      requestAnimationFrame(() => {
        this.tooltipElement.classList.replace("opacity-0", "opacity-100");
      });
    }, this.delayValue);
  }
  
  hideTooltip() {
    if (!this.tooltipElement) return;
    
    clearTimeout(this.showTimeout);
    
    this.tooltipElement.classList.replace("opacity-100", "opacity-0");
    
    this.hideTimeout = setTimeout(() => {
      this.tooltipElement.classList.add("hidden");
    }, 200); // Match transition duration
  }
  
  positionTooltip() {
    if (!this.tooltipElement) return;
    
    const buttonRect = this.element.getBoundingClientRect();
    
    // Make sure the tooltip is visible for measurements but fully transparent
    this.tooltipElement.classList.remove("hidden");
    this.tooltipElement.style.opacity = "0";
    
    const tooltipRect = this.tooltipElement.getBoundingClientRect();
    
    // Calculate position
    let top, left;
    
    switch (this.positionValue) {
      case "top":
        top = buttonRect.top - tooltipRect.height - this.offsetValue;
        left = buttonRect.left + (buttonRect.width - tooltipRect.width) / 2;
        break;
      case "right":
        top = buttonRect.top + (buttonRect.height - tooltipRect.height) / 2;
        left = buttonRect.right + this.offsetValue;
        break;
      case "bottom":
        top = buttonRect.bottom + this.offsetValue;
        left = buttonRect.left + (buttonRect.width - tooltipRect.width) / 2;
        break;
      case "left":
        top = buttonRect.top + (buttonRect.height - tooltipRect.height) / 2;
        left = buttonRect.left - tooltipRect.width - this.offsetValue;
        break;
      default:
        top = buttonRect.top - tooltipRect.height - this.offsetValue;
        left = buttonRect.left + (buttonRect.width - tooltipRect.width) / 2;
    }
    
    // Account for scroll position
    top += window.scrollY;
    left += window.scrollX;
    
    // Constrain to viewport
    left = Math.max(10, Math.min(left, window.innerWidth - tooltipRect.width - 10));
    top = Math.max(10, Math.min(top, window.innerHeight + window.scrollY - tooltipRect.height - 10));
    
    // Set position
    this.tooltipElement.style.top = `${top}px`;
    this.tooltipElement.style.left = `${left}px`;
    
    // Reset opacity to let the transition handle it
    this.tooltipElement.style.opacity = "";
  }
}
