// app/javascript/controllers/mobile_menu_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content"]
  
  connect() {
    // Close the menu when clicking outside
    document.addEventListener('click', this.closeIfClickedOutside.bind(this))
  }
  
  disconnect() {
    document.removeEventListener('click', this.closeIfClickedOutside.bind(this))
  }
  
  toggle(event) {
    event.stopPropagation()
    this.contentTarget.classList.toggle("is-open")
  }
  
  closeIfClickedOutside(event) {
    if (!this.element.contains(event.target) && this.contentTarget.classList.contains("is-open")) {
      this.contentTarget.classList.remove("is-open")
    }
  }
}