// app/javascript/controllers/token_details_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["details", "toggleButton", "expandIcon", "collapseIcon"]

  connect() {
    // Details start hidden
    this.detailsTarget.classList.add("hidden")
    this.expandIconTarget.classList.remove("hidden")
    this.collapseIconTarget.classList.add("hidden")
  }

  toggle(event) {
    event.preventDefault()
    
    // Toggle the display of details
    this.detailsTarget.classList.toggle("hidden")
    
    // Toggle the icon
    this.expandIconTarget.classList.toggle("hidden")
    this.collapseIconTarget.classList.toggle("hidden")
    
    // If details are now visible, load the details if they haven't been loaded yet
    if (!this.detailsTarget.classList.contains("hidden")) {
      const frame = this.detailsTarget.querySelector("turbo-frame")
      
      if (frame && !frame.hasAttribute("src") && !frame.hasAttribute("complete")) {
        // Get the token id from the DOM
        const tokenId = this.element.id.replace("token_", "")
        
        // Set the src to the token show path
        frame.setAttribute("src", `/tokens/${tokenId}`)
      }
    }
  }
}
