// app/javascript/controllers/session_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.setupSessionTimeoutHandling()
  }

  setupSessionTimeoutHandling() {
    document.addEventListener('turbo:before-fetch-response', this.handleResponse.bind(this))
  }

  handleResponse(event) {
    const response = event.detail.fetchResponse.response
    
    if (response.status === 401) {
      // Session expired, redirect to sign in
      window.location.href = '/users/sign_in'
      event.preventDefault()
    }
  }

  disconnect() {
    document.removeEventListener('turbo:before-fetch-response', this.handleResponse.bind(this))
  }
}