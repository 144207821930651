import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["lightIcon", "darkIcon"]

  connect() {
    // Check localStorage and apply the saved dark mode state
    const isDark = localStorage.getItem("dark-mode") === "true"
    const html = document.querySelector("html")
    
    if (isDark) {
      html.classList.add("dark")
    } else {
      html.classList.remove("dark") // Ensure light mode if not set
    }
  }

  toggle() {
    const html = document.querySelector("html")
    html.classList.toggle("dark")
    
    const isDark = html.classList.contains("dark")
    localStorage.setItem("dark-mode", isDark.toString())
  }
}