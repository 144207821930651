// app/javascript/controllers/dropdown_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content"]
  static values = { 
    open: Boolean,
    clickOutside: { type: Boolean, default: true }
  }

  connect() {
    // Initialize with closed state if not specified
    if (this.openValue === undefined) {
      this.openValue = false
    }

    // Add click outside listener if enabled
    if (this.clickOutsideValue) {
      this.addClickOutsideListener()
    }
  }

  disconnect() {
    // Remove click outside listener when controller disconnects
    if (this.clickOutsideValue) {
      document.removeEventListener('click', this.outsideClickHandler)
    }
  }

  toggle(event) {
    // Prevent default action (like following links)
    event.preventDefault()
    // Toggle the open state
    this.openValue = !this.openValue
  }

  show() {
    this.openValue = true
  }

  hide() {
    this.openValue = false
  }

  // Watch for changes to the open value
  openValueChanged() {
    if (this.openValue) {
      this.showDropdown()
    } else {
      this.hideDropdown()
    }
  }

  showDropdown() {
    this.contentTarget.classList.remove('hidden')
  }

  hideDropdown() {
    this.contentTarget.classList.add('hidden')
  }

  addClickOutsideListener() {
    this.outsideClickHandler = this.handleClickOutside.bind(this)
    document.addEventListener('click', this.outsideClickHandler)
  }

  handleClickOutside(event) {
    // If the dropdown is open and the click is outside the controller element
    if (this.openValue && !this.element.contains(event.target)) {
      this.openValue = false
    }
  }
}
