// app/javascript/controllers/notice_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    if (this.element) {
      //console.log("notice connected")
      this.element.style.opacity = '0'
      
      // Add transition class for both fade-in and fade-out
      this.element.classList.add('transition-all', 'duration-500', 'ease-out')
      
      // Get the current transform from Tailwind
      const currentTransform = getComputedStyle(this.element).transform
      
      // Only modify the Y position while preserving horizontal centering
      this.element.style.transform = `translateY(-20px)`
      
      // Trigger fade-in on next frame to ensure transition works
      requestAnimationFrame(() => {
        // Move to final position with full opacity
        this.element.style.opacity = '1'
        this.element.style.transform = `translateY(0)`
        
        // Set timeout to fade out the notice after 3 seconds
        setTimeout(() => {
          // For fade out, move up while fading
          this.element.style.opacity = '0'
          this.element.style.transform = `translateY(-20px)`
          
          // Remove the element from DOM after fade out is complete
          setTimeout(() => {
            this.element.remove()
          }, 500) // Match this with the transition duration
        }, 3000)
      })
    }
  }
}