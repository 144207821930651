import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ["overlay", "container", "closeButton"]
  
  connect() {
    // Calculate scrollbar width
    this.originalPaddingRight = document.body.style.paddingRight
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth
    
    // Store original overflow state
    this.originalOverflow = document.body.style.overflow
    
    // Store original navbar styles before modifying
    this.navbar = document.querySelector('nav')
    this.originalNavbarPaddingRight = this.navbar ? this.navbar.style.paddingRight : null
    this.originalNavbarRight = this.navbar ? this.navbar.style.right : null
    
    // Apply padding to compensate for scrollbar removal and disable scrolling
    document.body.style.paddingRight = `${scrollbarWidth}px`
    document.body.classList.add('overflow-hidden')
    
    // Fix navbar position when scrollbar disappears
    if (this.navbar) {
      // Apply the same padding to the navbar
      this.navbar.style.paddingRight = `${scrollbarWidth}px`
      // Keep the navbar locked to the right edge
      this.navbar.style.right = '0'
    }
    
    // Trigger entrance animations after a short delay to ensure DOM is ready
    setTimeout(() => {
      // Show the modal
      this.element.classList.add("opacity-100")
      this.element.classList.remove("opacity-0")
      
      // Animate overlay
      if (this.hasOverlayTarget) {
        this.overlayTarget.classList.add("opacity-100")
        this.overlayTarget.classList.remove("opacity-0")
      }
      
      // Animate container
      if (this.hasContainerTarget) {
        this.containerTarget.classList.add("opacity-100", "translate-y-0", "scale-100")
        this.containerTarget.classList.remove("opacity-0", "translate-y-4", "scale-95")
      }
    }, 50)
    
    // Add event listener for Escape key
    document.addEventListener('keydown', this.handleKeyDown.bind(this))
  }
  
  disconnect() {
    // Remove event listener for Escape key
    document.removeEventListener('keydown', this.handleKeyDown.bind(this))
    
    // Restore original padding and overflow
    document.body.style.paddingRight = this.originalPaddingRight
    document.body.classList.remove('overflow-hidden')
    
    // Restore navbar styles
    if (this.navbar) {
      this.navbar.style.paddingRight = this.originalNavbarPaddingRight
      this.navbar.style.right = this.originalNavbarRight
    }
  }
  
  handleKeyDown(event) {
    if (event.key === "Escape") {
      this.close()
    }
  }
  
  close(event) {
    // Prevent default behavior
    if (event && event.preventDefault) {
      event.preventDefault()
    }
    
    // Start exit animations
    this.element.classList.remove("opacity-100")
    this.element.classList.add("opacity-0")
    
    // Animate overlay out
    if (this.hasOverlayTarget) {
      this.overlayTarget.classList.remove("opacity-100")
      this.overlayTarget.classList.add("opacity-0")
    }
    
    // Animate container out
    if (this.hasContainerTarget) {
      this.containerTarget.classList.remove("opacity-100", "translate-y-0", "scale-100")
      this.containerTarget.classList.add("opacity-0", "translate-y-4", "scale-95")
    }
    
    // Wait for animation to complete before removing modal and restoring scroll
    setTimeout(() => {
      // Restore original padding and overflow AFTER animation completes
      document.body.style.paddingRight = this.originalPaddingRight
      document.body.classList.remove('overflow-hidden')
      
      // Restore navbar styles
      if (this.navbar) {
        this.navbar.style.paddingRight = this.originalNavbarPaddingRight
        this.navbar.style.right = this.originalNavbarRight
      }
      
      // Clear the turbo frame
      const frame = document.getElementById('remote_modal')
      if (frame) {
        frame.innerHTML = ''
      }
    }, 300)
  }
}
